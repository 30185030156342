import { map, catchError } from 'rxjs/operators';
import { Observable, empty } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { HelperService } from '../services/helper.service';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { User } from '../model';

@Injectable()
export class HttpClientService {
    constructor(
        // private http: Http,
        private httpClient: HttpClient,
        private storage: StorageService,
        private router: Router,
        private helper: HelperService,
    ) {}

    createAuthorizationHeader(headers: Headers) {
        const data = this.storage.getToken();
        if (data === null) {
            return this.router.navigate(['/login']);
        }

        headers.set('authorization', 'Bearer ' + data.access_token);
    }

    public post(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
            // headers: new HttpHeaders().set('Content-Type', 'application/json'),
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.post(url, data, httpOptions);
    }

    public put(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.put(url, data, httpOptions);
    }

    public delete(url: string, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.delete(url, httpOptions);
    }

    public get(url: string, options?: any): Observable<any> {
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.get(url, httpOptions);
    }

    public getAttachment(url: string): Observable<HttpEvent<any>> {
        const data = this.storage.getToken();
        const options = {
            responseType: 'blob',
            // headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token }),
        };
        // debugger;

        const req = new HttpRequest('GET', url, options);
        return this.httpClient.request(req);
    }

    public uploadFile(url: string, file: File, comment?: string): Observable<HttpEvent<any>> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('comment', comment);

        const data = this.storage.getToken();

        const params = new HttpParams();

        const options = {
            params: params,
            reportProgress: true,
            responseType: 'text' as 'text',
            headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token }),
        };

        // options.headers.append('content-length', '13110529');

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClient.request(req);
    }

    public refreshToken(): Observable<any> {
        const token = this.storage.getToken();
        const options = {
            headers: new HttpHeaders({
                authorization: 'Basic ' + environment.AUTHPASS,
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };

        return this.httpClient
            .post(
                this.helper.getAuthUrl(),
                'refresh_token=' + _.get(token, 'refresh_token', '') + '&grant_type=refresh_token',
                options,
            )
            .pipe(
                map((response) => {
                    this.storage.saveToken(response);
                    return response;
                }),
                catchError((e) => {
                    console.log(e);
                    this.storage.clearToken();
                    this.router.navigate(['/login']);
                    return empty();
                }),
            );
    }

    public pingApi(): Observable<any> {
        return this.get(this.helper.getAssistanceUrl(2) + '/userinfo/').pipe(
            map(
                () => true,
                () => false,
            ),
        );
    }
}
