import { AuthorizationNotification } from './../model/authorization/authorizationNotification.model';
import { SharedDocumentPost } from './../model/document/sharedDocumentPost.model';
import { DocumentWithSignature } from './../model/document/documentWithSignature.model';
import { DocumentSign } from './../model/document/documentSign.model';
import { HttpEventType } from '@angular/common/http';
import { AuthorityCase } from './../model/authorityCase/authorityCase.model';
import { AuthorityCaseRequest } from './../model/authorityCase/authorityCaseRequest.model';
import { Document } from './../model/document.model';
import { map, catchError, tap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Injectable } from '@angular/core';
import { AuthorizationOrder, AuthorizationRequest, Comment, Contractor, ReservationStatus } from '../model';
import { HistoryLog } from '../model';
import * as _ from 'lodash';
import { UpdateCasePricesRequestDTO } from '../model/authorityCase/updateCasePricesRequestDTO.model';

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    public getCaseTriageCommunication(bsId: number, data: any): Observable<AuthorizationNotification[]> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) + '/bs/' + bsId + '/case-triage/communicationLog',
            data,
        );
    }

    public updateCaseCoverage(data: UpdateCasePricesRequestDTO): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + '/updateCaseCoveragePrices', data);
    }

    //handleBusinessSubjectCase()
    public handleAuthorization(buid: number, data: AuthorizationRequest): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + '/bs/' + buid + '/cases', data);
    }

    public updateAuthorization(bsid: number, authorizationId: string, data: AuthorizationRequest): Observable<any> {
        let newData;
        if (data.service && data.service.length > 0) {
            newData = {
                service: {
                    id: _.get(data, 'service[0].id'),
                    name: _.get(data, 'service[0].name'),
                    coveragePercentage: _.get(data, 'service[0].coveragePercentage'),
                    coverageAmount: _.get(data, 'service[0].coverageAmount'),
                    price: _.get(data, 'service[0].price'),
                    phoneCall: _.get(data, 'service[0].phoneCall'),
                    telemed: _.get(data, 'service[0].telemed'),
                },
            };
        }
        // if (data.selectedSeparatedDate || data.selectedSeparatedDate === null) {
        newData = Object.assign(data);
        // }
        //PROBLEM ker zunanji izvajalec + čakalna vrsta nima datuma termina
        authorizationId = encodeURI(authorizationId);
        newData.case_id = authorizationId;
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) + `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(authorizationId)}`,
            newData,
        );
    }

    public updateBusinessSubjectSeperatedDateCase(bsid: number, authorizationId: string, data): Observable<any> {
        //Helper funkcija za eventSourcing, ki pomaga pri pošiljanju notifikacij nepovezanim izvajalcem
        return this.httpClient.put(
            this.helper.getAssistanceUrl(2) +
                `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(authorizationId)}/separated/date`,
            data,
        );
    }

    //handleBusinessSubjectCase()
    public handleAuthorizationFromAuthority(
        bsid: number,
        authorityid: number,
        data: AuthorizationRequest,
        guiVersion?: string,
    ): Observable<any> {
        var url = `/bs/${bsid}/authority/${authorityid}/cases`;
        if (guiVersion) {
            url += `?version=${guiVersion}`;
        }
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + url, data);
    }

    //getBusinessSubjectCases()
    public getBusinessSubjectCases(buid: string | number): Observable<AuthorizationOrder[]> {
        return this.httpClient
            .get(this.helper.getAssistanceUrl() + '/bs/' + buid + '/cases')
            .pipe(map((el) => el.map((el2) => new AuthorizationOrder().deserialize(el2))));
    }

    //addCaseComments()
    public addSharedDocumentToCustomer(buid: number, data: SharedDocumentPost): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(2) + `/bs/${buid}/documents`, data);
    }

    //addCaseComments()
    public addCaseComments(buid: number, caseid: string, data: Comment): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) + `/bs/${buid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/comments`,
            data,
            {
                responseType: 'text',
            },
        );
    }

    public addTriazComments(buid: number, triageId: string, data: Comment): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl() + `/bs/${buid}/triage/${triageId}/comments`, data, {
            responseType: 'text',
        });
    }

    // /bs/{bsid}/triage/{triageId}/comments

    //editCaseComment() not used --> although method available in adriabooking3
    public editCaseComments(buid: number, caseid: string, commentId: string, data: Comment): Observable<any> {
        return this.httpClient.put(
            this.helper.getAssistanceUrl() +
                `/bs/${buid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/comments/${commentId}`,
            data,
            {
                responseType: 'text',
            },
        );
    }

    //getSingleCase()
    public getSingleCase(bsid: string | number, caseid: string, claimFile?: string): Observable<AuthorizationOrder[]> {
        let queryParam = '';
        if (claimFile) {
            queryParam = `?claimFile=${claimFile}`;
        }

        return this.httpClient.get(
            this.helper.getAssistanceUrl(2) +
                `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}${queryParam}`,
        );
    }

    //getCaseComments()
    public getCaseComments(buid: string | number, caseid: string): Observable<Comment[]> {
        return this.httpClient
            .get(
                this.helper.getAssistanceUrl(2) +
                    `/bs/${buid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/comments`,
            )
            .pipe(
                map((el) => {
                    if (el.comments === null) {
                        return undefined;
                    }
                    return _.get(el, 'comments', []);
                }),
            );
    }

    //getCaseHistoryLog()
    public getCaseHistoryLog(bsid: string | number, caseid: string): Observable<HistoryLog[]> {
        return this.httpClient
            .get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/log`)
            .pipe(map((el) => el.map((el2) => new HistoryLog().deserialize(el2))));
    }

    //handleFileUpload()
    public uploadFile(file: File, comment: string, bsid: number): Observable<any> {
        return this.httpClient.uploadFile(this.helper.getAssistanceUrl(2) + `/upload/bs/${bsid}/`, file, comment);
    }

    //addCaseDocument()
    public addCaseDocument(bsid: number, caseid: string, documentId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) +
                `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/documents/${documentId}`,
        );
    }

    public addCaseCustomerDocument(bsid: number, caseid: string, documentId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) +
                `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/patientDocuments/${documentId}`,
        );
    }

    //getCaseDocuments()
    public getCaseDocuments(bsid: number, caseid: string): Observable<Document[]> {
        return this.httpClient
            .get(
                this.helper.getAssistanceUrl(2) +
                    `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/documents`,
            )
            .pipe(
                map((el) => {
                    if (_.isNil(el.documents)) {
                        return [];
                    }
                    return el.documents.map((document) => new Document().deserialize(document));
                }),
            );
    }

    public getCaseCustomerDocuments(bsid: number, caseid: string): Observable<Document[]> {
        return this.httpClient
            .get(
                this.helper.getAssistanceUrl(2) +
                    `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/patientDocuments`,
            )
            .pipe(
                map((el) => {
                    if (_.isNil(el.documents)) {
                        return [];
                    }
                    return el.documents.map((document) => new Document().deserialize(document));
                }),
            );
    }

    //getDocument()

    public getDocument(bsid: number, documentId: number | string): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/documents/${documentId}`);
    }

    public getSigenDocument(bsid: number, documentId: number | string): Observable<DocumentWithSignature> {
        return this.httpClient
            .getAttachment(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/documents/${documentId}`)
            .pipe(
                filter((resp) => resp.type == HttpEventType.Response),
                map((res: any) => {
                    const date: string = res.headers.get('X-SIGNATURE-DATE');
                    const signer: string = res.headers.get('X-SIGNATURE-SIGNER');
                    const contractor: string = res.headers.get('X-SIGNATURE-IZS');
                    const signature: DocumentSign = new DocumentSign().deserialize({
                        signer: signer,
                        date: date,
                        contractor: contractor,
                    });
                    const document: Blob = new Blob([res.body], {
                        type: res.body.type,
                    });

                    // const document: Blob = new Blob([res.body], {
                    //     type: 'application/pdf',
                    // });

                    if (_.isNil(date)) {
                        return new DocumentWithSignature().deserialize({ document: document, signature: undefined });
                    }

                    return new DocumentWithSignature().deserialize({ document: document, signature: signature });
                }),
            );
    }

    public getSecureDocument(guid: any, phoneCode: any, documentId: number | string): Observable<any> {
        // return this.httpClient.getAttachment('http://localhost:9090/booking/erp/docapi/contractors/54/documents/316');
        return this.httpClient.getAttachment(
            this.helper.getAssistanceUrl(2) +
                `/public/patientDocuments/link/${guid}/code/${phoneCode}/document/${documentId}`,
        );
    }

    //removeCaseDocument() not used --> although method available in adriabooking3
    public removeCaseDocument(bsid: string | number, caseid: string, documentId: number): Observable<Document[]> {
        return this.httpClient.delete(
            this.helper.getAssistanceUrl() +
                `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/documents/${documentId}`,
        );
    }

    //getCaseReservationStatuses()
    public getCaseReservationStatuses(bsid: number, caseid: string): Observable<HistoryLog[]> {
        return this.httpClient
            .get(
                this.helper.getAssistanceUrl(2) +
                    `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/statuses`,
            )
            .pipe(map((el) => el.map((el2: ReservationStatus) => new ReservationStatus().deserialize(el2))));
    }

    //getBusinessSubjectCasesForContractor()
    public getBusinessSubjectCasesForContractor(
        bsid: number,
        contractorid: string,
        bpi: string,
    ): Observable<AuthorizationOrder[]> {
        return this.httpClient
            .get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/cases/contractors/${contractorid}/bpi/${bpi}`)
            .pipe(map((el) => el.map((el2: any) => new AuthorizationOrder().deserialize(el2))));
    }

    //cancelBusinessSubjectCase()
    public cancelBusinessSubjectCase(bsId: any, caseId: string, contractor: Contractor = null): Observable<any> {
        if (!contractor || contractor.bpiType === 'MEDIFIT' || contractor.bpiType === 'SEPARATED') {
            return this.httpClient.delete(
                this.helper.getAssistanceUrl(2) + `/bs/${bsId}/cases/${this.helper.replaceSlahsForDolar(caseId)}/`,
                {
                    responseType: 'text',
                },
            );
        } else {
            return this.httpClient.delete(
                this.helper.getAssistanceUrl() +
                    `/bs/${bsId}/cases/${this.helper.replaceSlahsForDolar(caseId)}/contractors/${
                        contractor.externalId
                    }/${contractor.bpiType}`,
                { responseType: 'text' },
            );
        }
    }

    //getCaseContractorDocuments
    public getCaseContractorDocuments(bsid: number, caseid: string): Observable<Document[]> {
        return this.httpClient
            .get(
                this.helper.getAssistanceUrl(2) +
                    `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/contractor/documents`,
            )
            .pipe(
                map((el) => {
                    if (_.isNil(el.documents)) {
                        return [];
                    }
                    return el.documents.map((document) => new Document().deserialize(document));
                }),
            );
    }

    //getCaseContractorComments
    public getCaseContractorComments(bsid: number, caseid: string): Observable<Comment[]> {
        return this.httpClient
            .get(
                this.helper.getAssistanceUrl(2) +
                    `/bs/${bsid}/cases/${this.helper.replaceSlahsForDolar(caseid)}/contractor/comments`,
            )
            .pipe(
                map((el) => {
                    if (_.isNil(el.comments)) {
                        return undefined;
                    }
                    return _.get(el, 'comments', []);
                }),
            );
    }

    //getCaseContractorComments
    public selectContractor(
        bsId: number,
        caseId: string,
        contractorId: string,
        data: { contractorServiceExternalId?: string } = {},
    ): Observable<any> {
        return this.httpClient.post(
            this.helper.getAssistanceUrl(2) +
                `/bs/${bsId}/cases/${this.helper.replaceSlahsForDolar(caseId)}/contractors/${contractorId}`,
            data,
        );
    }
}
