import { Serializable } from '../deserialize.service';
import { Triage } from '../triage/triage.model';
import { Comment } from '../comment.model';
import { Document } from '../document.model';
import { BusinessCaseResponse } from '../authorization/businessCaseResponse.model';
import { User } from '../user.model';
import { Customer } from '../customer.model';
import { AuthorityCase, AuthorizationOrder, Contractor, PreReservation, ReservationStatus, Service, Slot } from '..';
import { AuthorityType } from '../../enums/authorityType.enum';
import * as _ from 'lodash';

export class AuthorityCaseAuthorization extends AuthorizationOrder implements Serializable<AuthorityCaseAuthorization> {
    public comments: Comment[];
    public documents: Document[];
    public contractorComments: Comment[];
    public contractorDocuments: Document[];
    public customerDocuments: Document[];

    deserialize(input: any) {
        // super.deserialize(input);
        if (input.caseAuthority) {
            this.caseAuthority = new AuthorityCase().deserialize(input.caseAuthority);
        }

        if (input.slot) {
            this.slot = input.slot;
        }
        if (input.caseId) {
            this.caseId = input.caseId;
        }
        // this.contractorId = input.contractorId;
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        if (input.services) {
            this.services = input.services.map((el) => new Service().deserialize(el));
        }
        this.numOfVisits = input.numOfVisits;
        if (input.user) {
            this.user = new User().deserialize(input.user);
        }

        if (input.orderedServices) {
            this.orderedServices = input.orderedServices.map((el) => new Service().deserialize(el));
        } else {
            this.orderedServices = [];
        }

        // if (_.get(input, 'contractor.id', 0) !== 0) {
        if (!_.isNil(input.contractor)) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        if (!_.isNil(input.contractorData)) {
            this.contractorData = input.contractorData;
        }
        if (input.caseDate) {
            this.caseDate = new Date(input.caseDate);
        }

        if (input.preReservation) {
            this.preReservation = new PreReservation().deserialize(input.preReservation);
        }
        if (input.waitingQueue) {
            this.waitingQueue = input.waitingQueue;
        }

        this.originalService = input.originalService;
        this.canceled = input.canceled;
        this.coveragePercentage = input.coveragePercentage;
        this.customerPrice = input.customerPrice;
        this.totalPrice = input.totalPrice;
        this.coveragePrice = input.coveragePrice;
        this.authorityType = input.authorityType;
        this.selectedSeparatedDate = input.selectedSeparatedDate;
        this.separatedConcluded = input.separatedConcluded;

        if (input.caseStatuses) {
            this.caseStatuses = input.caseStatuses.map((el) => new ReservationStatus().deserialize(el));
        }

        if (input.comments) {
            this.comments = input.comments.map((comment) => {
                return new Comment().deserialize(comment);
            });
        }

        if (input.documents) {
            this.documents = input.documents.map((document) => {
                return new Document().deserialize(document);
            });
        }

        if (input.contractorComments) {
            this.contractorComments = input.contractorComments.map((comment) => {
                return new Comment().deserialize(comment);
            });
        }

        if (input.contractorDocuments) {
            if (_.isArray(input.contractorDocuments.documents)) {
                this.contractorDocuments = input.contractorDocuments.documents.map((document) => {
                    return new Document().deserialize(document);
                });
            } else if (_.isArray(input.contractorDocuments)) {
                this.contractorDocuments = input.contractorDocuments.map((document) => {
                    return new Document().deserialize(document);
                });
            }
        }
        if (input.customerDocuments) {
            this.customerDocuments = input.customerDocuments.map((document) => {
                return new Document().deserialize(document);
            });
        }

        this.maxServices = input.maxServices;
        this.bsServiceId = input.bsServiceId;
        return this;
    }
}
