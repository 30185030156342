import { AdditionalData } from './../../../../../shared/model/authorization/additional-data';
import { OrgUnit } from './../../../../../shared/model/orgUnit.model';
import { UrlCase } from './../../../../../shared/model/auth/url-case.model';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Action, NgxsOnInit, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { combineLatest, concat, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, filter, flatMap, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import {
    AuthorityCase,
    AuthorityTypeStatus,
    AuthorizationRequest,
    Comment,
    Document,
    HistoryLog,
    ReservationStatus,
    Service,
    SharedDocument,
} from '../../../../../shared/model';
import { AuthorityCaseAttachment } from '../../../../../shared/model/authorityCase/authorityCaseAttachment.model';
//import { forEach } from '@angular/router/src/utils/collection';
import { AuthorityCaseAuthorization } from '../../../../../shared/model/authorityCase/authorityCaseAuthorization.model';
import { AuthorityCaseTriage } from '../../../../../shared/model/authorityCase/authorityCaseTriage.model';
import { ServiceBUContractor } from '../../../../../shared/model/service/serviceBUContractor.model';
import { Triage } from '../../../../../shared/model/triage/triage.model';
import { BusinessUserService } from '../../../../../shared/services/business-user.service';
import { ContractorsService } from '../../../../../shared/services/contractors.service';
import { AuthorityCaseStatus } from './../../../../../shared/enums/authorityCaseStatus.enum';
import { AuthorityType } from './../../../../../shared/enums/authorityType.enum';
import { UrlDataBS1 } from './../../../../../shared/model/auth/BS1/url-data-bs1.model';
import { UrlService } from './../../../../../shared/model/auth/BS1/url-service.model';
import { UrlData } from './../../../../../shared/model/auth/url-data.model';
import { AuthorityCases } from './../../../../../shared/model/authorityCase/authorityCases.model';
import { UpdateCasePricesRequestDTO } from './../../../../../shared/model/authorityCase/updateCasePricesRequestDTO.model';
import { AuthorizationOrder } from './../../../../../shared/model/authorization/authorizationOrder.model';
import { Placeholder } from './../../../../../shared/model/authorization/placeholder.model';
import { Contractor } from './../../../../../shared/model/contractor.model';
import { Customer } from './../../../../../shared/model/customer.model';
import { SharedDocumentPost } from './../../../../../shared/model/document/sharedDocumentPost.model';
import { Slot } from './../../../../../shared/model/slot.model';
import { User } from './../../../../../shared/model/user.model';
import { AuthorityCaseService } from './../../../../../shared/services/authority-case.service';
import { AuthorizationService } from './../../../../../shared/services/authorization.service';
import { HelperService } from './../../../../../shared/services/helper.service';
import { MfToastService } from './../../../../../shared/services/mfToast.service';
import { ReservationService } from './../../../../../shared/services/reservation.service';
import { StorageService } from './../../../../../shared/services/storage.service';
import { TriageService } from './../../../../../shared/services/triage.service';
import {
    AddComment,
    AddCustomerDocument,
    AddCustomerDocumentsSharedAndUploaded,
    AddCustomerSharedDocuments,
    AddDocument,
    CancelAuthorization,
    ChangeCoverage,
    ClearState,
    ConfirmAllPrereservations,
    ConfirmPrereservation,
    ConfirmSeparatedContractor,
    ConfirmWaitingQueue,
    CreateQueueBooking,
    EditCase,
    GetAuthorizationComments,
    GetAuthorizationContractorComments,
    GetAuthorizationContractorDocuments,
    GetAuthorizationCustomerDocuments,
    GetAuthorizationDocuments,
    GetTriageComments,
    GetTriageContractorComments,
    GetTriageContractorDocuments,
    GetTriageDocuments,
    LoadAuthorizationLog,
    LoadCaseStatuses,
    NewAuthorityCase,
    NewCase,
    NewCaseFromExistingAuthority,
    NewTriage,
    OpenAuthorityCase,
    OpenCase,
    OpenTriage,
    PrereservationCreated,
    Reschedule,
    SetAdditionalData,
    SetAuthorityType,
    SetCustomer,
    SetExternalUrlCase,
    SetInitComment,
    SetInitDocuments,
    SetLoading,
    SetNumOfServiceCase,
    SetSecondsLeft,
    SetSelectedOrgUnit,
    SetStaus,
    SetToken,
    SetUrlData,
    TriageSetContractor,
    UpdateAuthorization,
} from './../_actions/base-case.actions';

export class BaseCaseStateModel {
    case: AuthorityCaseAuthorization;
    authorityCases: AuthorityCases;
    customer: Customer;
    authorityCaseStatus: AuthorityCaseStatus;
    slot: Slot;
    secondsLeft: number;
    triage: AuthorityCaseTriage;
    selectedContractor: Contractor;
    selectedOrgUnit: OrgUnit | any;
    authorityType: AuthorityType;
    initAttachments: { comment: string; documents: File[] };
    loading: {
        status: boolean;
        message: string;
    };
    // caseIdForInput: string;
    urlData: UrlData;
    additionalData: AdditionalData;
    externalUrlCase: UrlCase;
    token: string;
    // caseRequest: AuthorizationRequest;
}

//https://www.linkedin.com/pulse/angular-ngxs-crud-example-dapton-tech/
const baseStateDefaults: BaseCaseStateModel = {
    case: undefined,
    authorityCases: undefined,
    customer: undefined,
    authorityCaseStatus: undefined,
    slot: undefined,
    secondsLeft: undefined,
    triage: null,
    selectedContractor: undefined,
    selectedOrgUnit: undefined,
    authorityType: undefined,
    initAttachments: undefined,
    loading: {
        status: false,
        message: '',
    },
    // caseIdForInput: undefined,
    urlData: undefined,
    additionalData: undefined,
    externalUrlCase: undefined,
    token: undefined,
};

@State<BaseCaseStateModel>({
    name: 'authorityCase',
    defaults: baseStateDefaults,
})
export class BaseCaseState implements NgxsOnInit {
    constructor(
        private authorizationRest: AuthorizationService,
        private storage: StorageService,
        public authCase: AuthorityCaseService,
        private helper: HelperService,
        private reservationRest: ReservationService,
        private toastr: MfToastService,
        private triageRest: TriageService,
        private contractorRest: ContractorsService,
        private BURest: BusinessUserService,
    ) {}

    ngxsOnInit(ctx: StateContext<BaseCaseStateModel>) {
        console.log('State initialized, now getting animals');
        ctx.dispatch(new SetLoading(false));
        //ctx.dispatch([new GetTriageComments(), new GetTriageDocuments()]);
    }

    // @Selector()
    // static getCaseIdForInput(state: BaseCaseStateModel): string {
    //     return state.caseIdForInput;
    // }

    @Selector()
    static getExternalUrlCase(state: BaseCaseStateModel): UrlCase {
        return state.externalUrlCase;
    }

    @Selector()
    static getSelectedContractor(state: BaseCaseStateModel): Contractor {
        return state.selectedContractor;
    }

    @Selector()
    static getTriage(state: BaseCaseStateModel): AuthorityCaseTriage {
        return state.triage;
    }

    @Selector()
    static getSlot(state: BaseCaseStateModel): Slot {
        return state.case.slot;
    }

    @Selector()
    static getState(state: BaseCaseStateModel): BaseCaseStateModel {
        return state;
    }

    @Selector()
    static getAuthorityCase(state: BaseCaseStateModel): AuthorityCases {
        return state.authorityCases;
    }

    @Selector()
    static getCase(state: BaseCaseStateModel): AuthorityCaseAuthorization {
        return state.case;
    }

    @Selector()
    static getCustomer(state: BaseCaseStateModel): Customer {
        return state.customer;
    }

    @Selector()
    static getAuthorityCaseStatus(state: BaseCaseStateModel): AuthorityTypeStatus {
        return new AuthorityTypeStatus().deserialize({ status: state.authorityCaseStatus, type: state.authorityType });
    }

    @Selector()
    static getUrlData(state: BaseCaseStateModel): UrlData {
        return state.urlData;
    }

    @Selector()
    static getSecondsLeft(state: BaseCaseStateModel): number {
        return state.secondsLeft;
    }

    @Selector()
    static getLoading(state: BaseCaseStateModel): { status: boolean; message: string } {
        return state.loading;
    }

    @Selector()
    static getToken(state: BaseCaseStateModel): string {
        return state.token;
    }

    @Selector()
    static getInitAttachments(state: BaseCaseStateModel): any {
        return state.initAttachments;
    }

    @Selector()
    static getAttachments(state: BaseCaseStateModel): AuthorityCaseAttachment[] {
        const attachments: AuthorityCaseAttachment[] = [];

        if (state.triage && state.triage.comments) {
            state.triage.comments.forEach((comment) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'comment',
                        local: true,
                        value: new Comment().deserialize(comment),
                    }),
                );
            });
        }

        if (state.triage && state.triage.documents) {
            state.triage.documents.forEach((document) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'document',
                        local: true,
                        value: new Document().deserialize(document),
                    }),
                );
            });
        }

        if (state.triage && state.triage.contractorComments) {
            state.triage.contractorComments.forEach((comment) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'comment',
                        local: false,
                        value: new Comment().deserialize(comment),
                    }),
                );
            });
        }

        if (state.triage && state.triage.contractorDocuments) {
            state.triage.contractorDocuments.forEach((document) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'document',
                        local: false,
                        value: new Document().deserialize(document),
                    }),
                );
            });
        }

        if (state.case && state.case.comments) {
            state.case.comments.forEach((comment) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'comment',
                        local: true,
                        value: new Comment().deserialize(comment),
                    }),
                );
            });
        }

        if (state.case && state.case.documents) {
            state.case.documents.forEach((document) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'document',
                        local: true,
                        value: new Document().deserialize(document),
                    }),
                );
            });
        }

        if (state.case && state.case.contractorComments) {
            state.case.contractorComments.forEach((comment) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'comment',
                        local: false,
                        value: new Comment().deserialize(comment),
                    }),
                );
            });
        }

        if (state.case && state.case.contractorDocuments) {
            state.case.contractorDocuments.forEach((document) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'document',
                        local: false,
                        value: new Document().deserialize(document),
                    }),
                );
            });
        }

        attachments.sort((a: AuthorityCaseAttachment, b: AuthorityCaseAttachment) => {
            let result = -1;
            if (a.dateAdded > b.dateAdded) {
                result = 1;
            }

            return result;
        });

        return attachments;
    }

    @Selector()
    static getCustomerAttachments(state: BaseCaseStateModel): AuthorityCaseAttachment[] {
        const attachments: AuthorityCaseAttachment[] = [];

        if (state.case && state.case.customerDocuments) {
            state.case.customerDocuments.forEach((document) => {
                attachments.push(
                    new AuthorityCaseAttachment().deserialize({
                        type: 'document',
                        local: true,
                        value: new Document().deserialize(document),
                    }),
                );
            });
        }
        attachments.sort((a: AuthorityCaseAttachment, b: AuthorityCaseAttachment) => {
            let result = -1;
            if (a.dateAdded > b.dateAdded) {
                result = 1;
            }

            return result;
        });
        return attachments;
    }

    //ACTIONS/////////////////////////////////////////////////////////////////////////////////////////////////////////////
    @Action(ClearState)
    public ClearState(ctx: StateContext<BaseCaseStateModel>, {}: ClearState) {
        ctx.patchState(baseStateDefaults);
    }

    @Action(OpenAuthorityCase)
    openAuthorityCase({ setState, dispatch }: StateContext<BaseCaseStateModel>, { payload }: OpenAuthorityCase) {
        let authorizationDetails$;
        let triageDetails$;
        setState({
            ...baseStateDefaults,
        });
        dispatch(new SetLoading(true, 'Nalaganje primera...'));
        if (payload.caseId) {
            authorizationDetails$ = this.authorizationRest.getSingleCase(
                this.storage.getBusinessSubject().id,
                payload.caseId,
            );
        } else {
            authorizationDetails$ = of([]);
        }

        if (payload.triageId) {
            triageDetails$ = this.triageRest.getTriage(this.storage.getBusinessSubject().id, payload.triageId);
        } else {
            triageDetails$ = of(null);
        }

        return combineLatest([authorizationDetails$, triageDetails$]).pipe(
            switchMap(([cases, triage]: [AuthorizationOrder[], Triage]) => {
                let authorityDetails$ = of(null);
                if (cases.length > 0 && !_.isNil(cases[0].caseAuthority.id)) {
                    authorityDetails$ = this.authCase.getAuthorityCasesDetails(
                        this.storage.getBusinessSubject().id,
                        cases[0].caseAuthority.id,
                    );
                } else if (triage && !_.isNil(triage.caseAuthority.id)) {
                    authorityDetails$ = this.authCase.getAuthorityCasesDetails(
                        this.storage.getBusinessSubject().id,
                        triage.caseAuthority.id,
                    );
                }

                return authorityDetails$.pipe(
                    switchMap((authorityDetails) => {
                        let contractorId: string;
                        let caseReservationStatus$ = of([]);

                        let contractorServices$ = of([]);

                        let authorityCaseStatus: AuthorityCaseStatus = AuthorityCaseStatus.AUTHORITY_CREATED;

                        const order: any = {
                            case: null,
                            triage: null,
                            customer: null,
                            authorityCases: null,
                            selectedContractor: null,
                            authorityType: AuthorityType.CASE,
                            authorityCaseStatus: AuthorityCaseStatus.AUTHORITY_CREATED,
                            slots: null,
                            claimFile: null,
                            //secondsLeft: 0,
                        };

                        if (authorityDetails.triage.length > 0) {
                            order.triage = authorityDetails.triage[0];

                            order.triage.comments = [];
                            order.triage.documents = [];
                            order.triage.contractorComments = [];
                            order.triage.contractorDocuments = [];

                            order.authorityType = AuthorityType.TRIAGE;
                            order.authorityCaseStatus = AuthorityCaseStatus.TRIAGE_CREATED;

                            if (!order.customer) {
                                order.customer = authorityDetails.triage[0].customer;
                            }

                            if (!order.authorityCases) {
                                order.authorityCases = authorityDetails.triage[0].caseAuthority;
                            }

                            if (order.triage.status) {
                                order.authorityCaseStatus = order.triage.status;
                            } else {
                                if (authorityDetails.triage[0].canceledTime) {
                                    order.authorityCaseStatus = AuthorityCaseStatus.CANCELED;
                                }
                            }

                            contractorId = authorityDetails.triage[0].contractor.contractorId;

                            if (order.triage.serviceId) {
                                if (order.triage.serviceId === -999) {
                                    contractorServices$ = of([
                                        new ServiceBUContractor().deserialize({
                                            bsServiceId: -999,
                                            bsServiceName: 'Storitev po dogovoru',
                                            contractorId: contractorId.toString(),
                                            contractorServiceId: -999,
                                            contractorServiceName: 'Storitev po dogovoru',
                                            price: order.triage.price,
                                        }),
                                    ]);
                                } else {
                                    contractorServices$ = this.BURest.getContractorServicesForBus(
                                        this.storage.getBusinessSubject().id,
                                        contractorId.toString(),
                                    );
                                }
                            }
                        }

                        if (authorityDetails.cases.length > 0) {
                            //več primerov pod istim authorityId-jem
                            const targetAuthority = authorityDetails.cases.find(
                                (auth) => auth.caseId == payload.caseId,
                            );
                            caseReservationStatus$ = this.authorizationRest.getCaseReservationStatuses(
                                this.storage.getBusinessSubject().id,
                                targetAuthority.caseId,
                            );

                            if (!order.authorityCases) {
                                order.authorityCases = targetAuthority.caseAuthority;
                            }

                            if (!order.customer) {
                                order.customer = targetAuthority.customer;
                            }

                            if (!order.contractor) {
                                order.contractor = targetAuthority.contractor;
                            }

                            order.authorityCaseStatus = AuthorityCaseStatus.AUTHORITY_CREATED;
                            if (
                                targetAuthority.contractor &&
                                targetAuthority.contractor.contractorId !== '' &&
                                targetAuthority.contractor.bpi === 'SEPARATED'
                            ) {
                                contractorId = targetAuthority.contractor.contractorId;
                                authorityCaseStatus = AuthorityCaseStatus.CONFIRMED;
                            } else if (targetAuthority.contractor && targetAuthority.contractor.contractorId !== '') {
                                contractorId = targetAuthority.contractor.contractorId;
                                authorityCaseStatus = AuthorityCaseStatus.CREATED;
                            } else if (
                                authorityDetails.triage[0] &&
                                targetAuthority.contractor &&
                                authorityDetails.triage[0].contractor.contractorId !== ''
                            ) {
                                contractorId = authorityDetails.triage[0].contractor.contractorId;
                                authorityCaseStatus = AuthorityCaseStatus.CONTRACTOR_SELECTED;
                            } else {
                                authorityCaseStatus = AuthorityCaseStatus.CREATED;
                            }

                            order.case = targetAuthority;
                            order.authorityCaseStatus = authorityCaseStatus;
                            order.authorityType = AuthorityType.CASE;

                            if (targetAuthority.additionalData) {
                                order.additionalData = targetAuthority.additionalData;
                            }
                        }

                        let contractor$ = of(null);
                        if (contractorId) {
                            contractor$ = this.contractorRest.getContractorForBusinessUnit(
                                this.storage.getBusinessSubject().id,
                                contractorId,
                            );
                        } else if (order.triage && order.case) {
                            //avtorizacija iz triaže
                            contractor$ = this.contractorRest.getContractorForBusinessUnit(
                                this.storage.getBusinessSubject().id,
                                _.get(order, 'triage.contractor.id'),
                            );
                        }

                        return combineLatest([caseReservationStatus$, contractor$, contractorServices$]).pipe(
                            map(([caseStatuses, contractor, contractorServices]) => {
                                if (caseStatuses.length > 0) {
                                    order.authorityCaseStatus = AuthorityCaseStatus.CONFIRMED;
                                    order.case.caseStatuses = caseStatuses;
                                    order.case.numOfVisits = caseStatuses.length;
                                }

                                if (cases.length > 0 && cases[0].canceled) {
                                    order.authorityCaseStatus = AuthorityCaseStatus.CANCELED;
                                }

                                if (contractor) {
                                    order.selectedContractor = contractor;
                                    if (order.case) {
                                        order.case.contractor = contractor;
                                    }
                                }
                                if (order.case && !order.case.numOfVisits) {
                                    order.case.numOfVisits = order.case.maxServices ? order.case.maxServices : 1;
                                }

                                if (contractorServices.length > 0 && order.triage.serviceId) {
                                    const businessService = contractorServices.find((el) => {
                                        return el.contractorServiceId === order.triage.serviceId;
                                    });

                                    order.triage.bsService = businessService;
                                }

                                if (authorityDetails) {
                                    order.authorityCases = authorityDetails;
                                    //samo če si med urejanjem casa refreshal se ti ni shranil user
                                    if (!_.isNil(order.case) && !order.case.user) {
                                        order.case.user = new User().deserialize({
                                            id: order.authorityCases.appUserId,
                                            name: order.authorityCases.appUserName,
                                        });
                                    }
                                }

                                if (!order.customer || !order.customer.id) {
                                    if (authorityDetails && authorityDetails.customer) {
                                        order.customer = authorityDetails.customer;
                                    }
                                }

                                return order as any;
                            }),
                        );
                    }),
                );
            }),
            tap((response: BaseCaseStateModel) => {
                dispatch(new SetLoading(false));

                setState({
                    ...baseStateDefaults,
                    ...response,
                });

                /*
                dispatch([
                    new GetTriageComments(),
                    new GetTriageDocuments(),
                    new GetTriageContractorComments(),
                    new GetTriageContractorDocuments(),
                    new GetAuthorizationComments(),
                    new GetAuthorizationDocuments(),
                    new GetAuthorizationContractorComments(),
                    new GetAuthorizationContractorDocuments(),
                    new GetAuthorizationCustomerDocuments(),
                ]);*/
            }),
            catchError((e) => {
                dispatch(new SetLoading(false));
                return of(e);
            }),
        );
    }

    @Action(LoadCaseStatuses)
    loadCaseStatuses({ getState, patchState }: StateContext<BaseCaseStateModel>, {}: LoadCaseStatuses) {
        const state = getState();
        this.authorizationRest
            .getCaseReservationStatuses(this.storage.getBusinessSubject().id, state.case.caseId)
            .subscribe((res: any) => {
                res = _.orderBy(res, ['caseInternalId']);
                // res.map((el: any) => new AuthorityCaseAuthorization().deserialize(el));

                const newState = getState();
                const caseEdit: AuthorityCaseAuthorization = _.cloneDeep(newState.case);
                caseEdit.caseStatuses = res.map((el: any) => new ReservationStatus().deserialize(el));
                patchState({
                    ...newState,
                    case: caseEdit,
                });
            });
    }

    @Action(LoadAuthorizationLog)
    loadAuthorizationLog({ getState, patchState }: StateContext<BaseCaseStateModel>, {}: LoadAuthorizationLog) {
        const state = getState();
        this.authorizationRest
            .getCaseHistoryLog(this.storage.getBusinessSubject().id, state.case.caseId)
            .subscribe((res) => res.map((el) => new HistoryLog().deserialize(el)));
    }

    @Action(OpenCase)
    openCase({ patchState }: StateContext<BaseCaseStateModel>, { payload }: TriageSetContractor) {
        // debugger;
        // patchState({
        //     authorityCaseStatus: AuthorityCaseStatus.CONTRACTOR_SELECTED,
        //     triageRequest: new TriageRequest().deserialize({ contractor: payload }),
        //     selectedContractor: payload,
        // });
    }

    @Action(OpenTriage)
    openTriage({ patchState }: StateContext<BaseCaseStateModel>, { payload }: TriageSetContractor) {
        // debugger;
        // patchState({
        //     authorityCaseStatus: AuthorityCaseStatus.CONTRACTOR_SELECTED,
        //     triageRequest: new TriageRequest().deserialize({ contractor: payload }),
        //     selectedContractor: payload,
        // });
    }

    @Action(NewTriage)
    createTriage({ getState, patchState, setState, dispatch }: StateContext<BaseCaseStateModel>, {}: NewTriage) {
        const state = getState();
        if (
            !(
                state.initAttachments &&
                (state.initAttachments.comment ||
                    (state.initAttachments.documents && state.initAttachments.documents.length > 0))
            )
        ) {
            return throwError('Preden lahko oddate eTriažo, vnesite komentar ali pripnite datoteko.');
        }

        dispatch(new SetLoading(true, 'Kreiranje eTriaže v teku...'));
        return this.triageRest
            .createTriage(
                this.storage.getBusinessSubject().id,
                state.authorityCases.id,
                new Triage().deserialize({
                    customer: state.customer,
                    contractor: {
                        id: state.selectedContractor.id,
                        externalId: state.selectedContractor.externalId,
                        bpiType: state.selectedContractor.bpiType,
                    },
                }),
            )
            .pipe(
                tap(
                    (payload) => {
                        const newState = getState();
                        const newTriage: AuthorityCaseTriage = new AuthorityCaseTriage().deserialize(payload);
                        patchState({
                            authorityCaseStatus: newTriage.status as AuthorityCaseStatus,
                            triage: newTriage,
                        });

                        if (newState.initAttachments.comment) {
                            dispatch(new AddComment(state.initAttachments.comment)).subscribe(() => {
                                patchState({
                                    initAttachments: {
                                        comment: undefined,
                                        documents: newState.initAttachments
                                            ? newState.initAttachments.documents
                                            : undefined,
                                    },
                                });
                            });
                        }

                        if (newState.initAttachments.documents && newState.initAttachments.documents.length > 0) {
                            newState.initAttachments.documents.forEach((document) => {
                                dispatch(new AddDocument(document)).subscribe(() => {
                                    patchState({
                                        initAttachments: {
                                            comment: newState.initAttachments
                                                ? newState.initAttachments.comment
                                                : undefined,
                                            documents: undefined,
                                        },
                                    });
                                });
                            });
                        }
                        dispatch(new SetLoading(false));
                    },
                    (err) => {
                        dispatch(new SetLoading(false));
                        //this.toastr.error('Napaka pri kreiranju ePosveta');
                    },
                ),
            );
    }

    @Action(TriageSetContractor)
    triageSetContractor({ patchState }: StateContext<BaseCaseStateModel>, { payload }: TriageSetContractor) {
        patchState({
            authorityCaseStatus: AuthorityCaseStatus.TRIAGE_CONTRACTOR_SELECTED,
            triage: new AuthorityCaseTriage().deserialize({ contractor: payload }),
            selectedContractor: payload,
        });
    }

    @Action(SetUrlData)
    setUrlData(ctx: StateContext<BaseCaseStateModel>, { payload }: SetUrlData) {
        debugger;
        // ctx.setState(
        //     patch<BaseCaseStateModel>({ urlData: payload }),
        // );

        ctx.patchState({
            urlData: payload,
        });
    }

    @Action(SetSecondsLeft)
    setSecondsLeft({ patchState }: StateContext<BaseCaseStateModel>, { payload }: SetSecondsLeft) {
        patchState({
            secondsLeft: payload,
        });
    }

    @Action(SetLoading)
    setLoading({ patchState }: StateContext<BaseCaseStateModel>, { status, message }: SetLoading) {
        if (status) {
            patchState({
                loading: {
                    status: status,
                    message: message,
                },
            });
        } else {
            patchState({
                loading: {
                    status: status,
                    message: null,
                },
            });
        }
    }

    @Action(PrereservationCreated)
    setPrereservation(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        { contractor, prereservation, slot, contractorData }: PrereservationCreated,
    ) {
        const state = getState();
        const caseEdit: AuthorityCaseAuthorization = _.cloneDeep(state.case);
        //ce je bil izbran VIP termin, dodamo id zavarovalnice - zato, da znamo pokazati koliko treminov je bilo poravljenih
        if (_.has(slot, 'bsids')) {
            prereservation.leasedBs = this.storage.getBusinessSubject().id;
        }

        prereservation;
        caseEdit.preReservation = prereservation;
        caseEdit.contractor = contractor;
        caseEdit.slot = slot;
        caseEdit.caseDate = new Date();
        caseEdit.contractorData = contractorData;

        patchState({
            ...state,
            case: caseEdit,
            authorityCaseStatus: AuthorityCaseStatus.PRERESERVATION,
            selectedContractor: contractor,
            secondsLeft: 120,
        });
    }

    @Action(Reschedule)
    reschedule({ patchState, getState, dispatch }: StateContext<BaseCaseStateModel>, { payload }: Reschedule) {
        dispatch(new SetLoading(true, 'Potrjevanje novega termina...'));

        const state = getState();
        if (state.selectedContractor && state.selectedContractor.bpiType === 'SEPARATED') {
            const newDate = payload.dateFrom ? payload.dateFrom.toISOString() : null;
            const data = new AuthorizationRequest().deserialize({
                selectedSeparatedDate: newDate,
            });
            const status = _.get(state, 'authorityCaseStatus');
            if (newDate) {
                //za eventSource reschedule za zunanjega izvajalca --> dobi notification
                //če separated naredi reschedule, ampak nima potrjene avtorizacije --> se ne izvede update klic
                if (status == AuthorityCaseStatus.CONFIRMED) {
                    const newCase: AuthorityCaseAuthorization = _.cloneDeep(state.case);
                    newCase.selectedSeparatedDate = newDate;
                    patchState({
                        case: newCase,
                    });
                    dispatch(new SetLoading(false));
                } else {
                    this.authorizationRest
                        .updateBusinessSubjectSeperatedDateCase(
                            this.storage.getBusinessSubject().id,
                            _.get(state, 'case.caseId'),
                            data,
                        )
                        .pipe(
                            tap(() => {
                                const newCase: AuthorityCaseAuthorization = _.cloneDeep(state.case);
                                newCase.selectedSeparatedDate = newDate;
                                patchState({
                                    authorityCaseStatus: AuthorityCaseStatus.CONFIRMED,
                                    case: newCase,
                                });
                                const comment =
                                    `Datum termina spremenjen na: ` +
                                    moment(newDate).format('DD.MM.YYYY') +
                                    ` ob ` +
                                    moment(newDate).format('HH:mm');
                                dispatch(new AddComment(comment)).subscribe();
                                dispatch(new SetLoading(false));
                            }),
                        )
                        .subscribe(() => {
                            dispatch(new SetLoading(false));
                        });
                }
            }
        } else if (state.selectedContractor) {
            //MEDIFIT + X21
            const newData = {
                ...{
                    appUserId: this.storage.getUserData().id,
                    appUserName: this.storage.getUserData().name,
                    sendEmail: false,
                    sendSms: false,
                },
                ...payload,
            };
            delete newData.prereservationId;
            let updateApi$;
            if (state.selectedContractor.bpiType === 'MEDIFIT') {
                updateApi$ = this.reservationRest.updateReservation(payload.prereservationId, newData);
            } else {
                updateApi$ = this.reservationRest.updateReservation(
                    payload.prereservationId,
                    newData,
                    state.selectedContractor.bpiType,
                );
            }
            return updateApi$.pipe(
                tap(
                    () => {
                        dispatch(new SetLoading(false));
                    },
                    () => {
                        dispatch(new SetLoading(false));
                    },
                ),
            );
        }
    }

    @Action(SetCustomer)
    setCustomer({ patchState }: StateContext<BaseCaseStateModel>, { payload }: SetCustomer) {
        patchState({
            customer: new Customer().deserialize(payload),
        });
    }

    @Action(NewAuthorityCase)
    createAuthorityCase(ctx: StateContext<BaseCaseStateModel>, { payload }: NewAuthorityCase) {
        //iframe integracija
        const birthDateString = payload.customer.birthDateString;
        //24 prva
        if (this.storage.getBusinessSubject().id != 24 && !payload.customer.phone) {
            return throwError({
                errorType: 'missingPhone',
            });
        }
        return this.authCase.createCaseAuthority(this.storage.getBusinessSubject().id, payload).pipe(
            tap((data) => {
                if (_.isNil(payload.customer.medifitId)) {
                    payload.customer = data.customer;
                }
                payload.customer.birthDateString = birthDateString;

                // ctx.setState(
                //     patch<BaseCaseStateModel>({
                //         caseIdForInput: payload.case_id,
                //         authorityCases: new AuthorityCases().deserialize(data),
                //         customer: new Customer().deserialize(payload.customer),
                //         authorityCaseStatus: AuthorityCaseStatus.AUTHORITY_CREATED,
                //     }),
                // );
                ctx.setState({
                    ...baseStateDefaults,
                    externalUrlCase: payload.case,
                    authorityCases: new AuthorityCases().deserialize(data),
                    customer: new Customer().deserialize(payload.customer),
                    authorityCaseStatus: AuthorityCaseStatus.AUTHORITY_CREATED,
                });
            }),
        );
    }

    @Action(SetExternalUrlCase)
    setExternalUrlCase(ctx: StateContext<BaseCaseStateModel>, { payload }: SetExternalUrlCase) {
        ctx.setState({
            ...ctx.getState(),
            externalUrlCase: payload,
        });
    }

    @Action(ConfirmPrereservation)
    confirmPrereservation(
        { patchState, getState, dispatch }: StateContext<BaseCaseStateModel>,
        {}: ConfirmPrereservation,
    ) {
        const state = getState();

        // if (_.has(state, 'case.slot.bsids')) {
        //     data.leasedBs = this.storage.getBusinessSubject().id;
        // }
        dispatch(new SetLoading(true, 'Potrjevanje izbranega termina...'));
        return this.reservationRest.sendResevation(state.case.preReservation, state.case.contractor.bpiType).pipe(
            tap(
                (res) => {
                    this.toastr.success('Rezervacija uspešna.');
                    patchState({
                        authorityCaseStatus: AuthorityCaseStatus.CONFIRMED,
                    });
                    dispatch(new SetLoading(false));
                },
                () => {
                    this.toastr.warning('Napaka pri rezervaciji.');
                    dispatch(new SetLoading(false));
                },
            ),
        );
    }

    @Action(ConfirmAllPrereservations)
    ConfirmAllPrereservations({ patchState, getState, dispatch }: StateContext<BaseCaseStateModel>, { data }: any) {
        dispatch(new SetLoading(true, 'Potrjevanje izbranega termina...'));
        return this.reservationRest.confirmPrereservations(data).pipe(
            tap(
                (res) => {
                    this.toastr.success('Osnutki uspešno ustvarjeni.');
                    patchState({
                        authorityCaseStatus: AuthorityCaseStatus.CONFIRMED,
                    });
                    dispatch(new SetLoading(false));
                },
                (err) => {
                    if (this.helper.getStructErrorCode(err).indexOf('usr:') > -1) {
                        this.toastr.error(this.helper.getStructErrorMessage(err));
                    } else {
                        this.toastr.warning('Napaka pri ustvarjanju osnutkov.');
                    }
                    patchState({
                        authorityCaseStatus: AuthorityCaseStatus.PRERESERVATION,
                    });
                    dispatch(new SetLoading(false));
                },
            ),
        );
    }

    @Action(ConfirmWaitingQueue)
    confirmWaitingQueue(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        { contractor }: ConfirmWaitingQueue,
    ) {
        const state = getState();

        this.toastr.success('Rezervacija v čakalno vrsto uspešna.');
        patchState({
            authorityCaseStatus: AuthorityCaseStatus.CONFIRMED,
            selectedContractor: contractor,
        });
    }

    @Action(ConfirmSeparatedContractor)
    confirmSeparatedContractor(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        { contractor }: ConfirmSeparatedContractor,
    ) {
        const state = getState();
        const newCase: AuthorityCaseAuthorization = _.cloneDeep(state.case);
        newCase.contractor = contractor;
        this.toastr.success('Izbira izvajalca uspešna.');
        patchState({
            authorityCaseStatus: AuthorityCaseStatus.CONFIRMED,
            selectedContractor: contractor,
            case: newCase,
        });
    }

    @Action(CancelAuthorization)
    cancelAuthorization({ patchState, getState, dispatch }: StateContext<BaseCaseStateModel>, {}: AuthorityCase) {
        const state = getState();

        dispatch(new SetLoading(true, 'Preklicovanje avtorizacije...'));
        return this.authorizationRest
            .cancelBusinessSubjectCase(this.storage.getBusinessSubject().id, state.case.caseId, state.case.contractor)
            .pipe(
                tap(
                    (res) => {
                        this.toastr.success('Preklic avtorizacije uspešen.');
                        const newCase = _.cloneDeep(state.case);
                        newCase.caseId = res;
                        patchState({
                            authorityCaseStatus: AuthorityCaseStatus.CANCELED,
                            case: newCase,
                        });
                        dispatch(new SetLoading(false));
                    },
                    () => {
                        this.toastr.error('Napaka pri preklicu avtoriazcije.');
                        dispatch(new SetLoading(false));
                    },
                ),
            );
    }

    @Action(CreateQueueBooking)
    createQueueBooking({ patchState, getState }: StateContext<BaseCaseStateModel>, {}: CreateQueueBooking) {
        // TINE: TODO
        // const state = getState();
        // let data = new AddToWaitingQueueRequest().deserialize({
        //     userId: this.storage.getUserData().id + '',
        //     userName: this.storage.getUserData().name,
        //     contractor: termDetails.contractor,
        //     customer: {
        //         id: termDetails.customer.medifitId
        //     },
        //     comment: undefined,
        //     isBs: true,
        //     bsid: this.storage.getBusinessSubject().id,
        //     caseId: _.get(termDetails, 'authorization.caseId'),
        //     orderedServices: []
        // });
        // _.times(_.get(termDetails, 'authorization.numOfVisits', 0), () => {
        //     data.orderedServices.push(termDetails.service.id);
        // });
        // this.reservationRest.addServicesToWaitingQueue(data).subscribe(res => {
        //     this.toastr.success('Uspešno dodano v čakalno vrsto!');
        //     // this.onReservation.next(termDetails);
        // });
    }

    @Action(UpdateAuthorization)
    updateAuthorization(
        { getState, patchState, setState }: StateContext<BaseCaseStateModel>,
        { authorizationId, payload }: UpdateAuthorization,
    ) {
        const state = getState();
        const newData: any = _.cloneDeep(payload);
        if (newData.selectedSeparatedDate) {
            if (newData.selectedSeparatedDate instanceof moment) {
                newData.selectedSeparatedDate = newData.selectedSeparatedDate.toISOString();
            }
        }
        if (newData.service && newData.service.length > 1) {
            newData.service = newData.service[0];
        }
        if (!newData.bsServiceId) {
            newData.bsServiceId = _.get(state, 'case.bsServiceId', null);
        }
        if (!newData.bsServiceId) {
            newData.bsServiceId = _.get(newData, 'service.0.originalService.id', _.get(newData, 'service.0.id', null));
        }

        let updateAuth$;
        if (newData.selectedSeparatedDate && !newData.service) {
            //izbereš termin iz popupa za separated contractorja ---> ne kliči updateAuthorization
            updateAuth$ = of(true);
        } else {
            updateAuth$ = this.authorizationRest.updateAuthorization(
                this.storage.getBusinessSubject().id,
                authorizationId,
                newData,
            );
        }

        return this.authorizationRest
            .updateCaseCoverage(
                new UpdateCasePricesRequestDTO().deserialize({
                    bsId: this.storage.getBusinessSubject().id,
                    caseId: state.case.caseId,
                    totalPrice: _.get(state.case, 'totalPrice', 0),
                    customerPrice: _.get(state.case, 'customerPrice', 0),
                    coveragePrice: _.get(state.case, 'coveragePrice', 0),
                    coveragePercentage: _.get(state.case, 'coveragePercentage', 0),
                    numberOfServices: _.get(state.case, 'numOfVisits'),
                }),
            )
            .pipe(
                mergeMap((val) => {
                    return updateAuth$.pipe(
                        tap(() => {
                            const newCase: AuthorityCaseAuthorization = _.cloneDeep(state.case);
                            if (payload.service && payload.service.length > 0) {
                                //če je zunanji izvajalec ne updejtamo storitve
                                // if (!_.get(payload, 'service[0].externalId')) {
                                newCase.services = [
                                    new Service().deserialize({
                                        id: payload.service[0].id,
                                        externalId: payload.service[0].externalId,
                                        name: payload.service[0].name,
                                        price: payload.service[0].price,
                                        coveragePercentage: _.get(payload, 'service[0].coveragePercentage', 0),
                                        coverageAmount: _.get(payload, 'service[0].coverageAmount', 0),
                                        selfCoveragePercentage: _.get(payload, 'service[0].selfCoveragePercentage', 0),
                                        selfCoverageAmount: _.get(payload, 'service[0].selfCoverageAmount', 0),
                                        originalService: _.get(payload, 'service[0].originalService'),
                                        phoneCall: _.get(payload, 'service[0].phoneCall'),
                                        telemed: _.get(payload, 'service[0].telemed'),
                                    }),
                                ];
                                newCase.originalService = _.get(payload, 'service[0].originalService');
                                // }
                                //če nima totalPrice si pravkar izbral storitev v prvo (nisi izbral storitve in jo kasneje menjal)
                                if (_.isNil(newCase.totalPrice)) {
                                    _.set(
                                        newCase,
                                        'totalPrice',
                                        payload.service[0].price * _.get(payload, 'numOfVisits', 1),
                                    );
                                    _.set(newCase, 'coveragePercentage', 100);
                                    _.set(
                                        newCase,
                                        'coveragePrice',
                                        payload.service[0].price * _.get(payload, 'numOfVisits', 1),
                                    );
                                    //TODO
                                    // if (newCase.maxServices) {
                                    //     _.set(newCase, 'numOfVisits', newCase.maxServices);
                                    // } else {
                                    //     _.set(newCase, 'numOfVisits', 1);
                                    // }
                                } else {
                                    if (state.triage && state.case) {
                                        //avtorizacija iz eTriaže --> vzemi totalPrice iz forma (lahko ročno nastavijo  )
                                    } else {
                                        //če ima boš dal potrdi gumb po urejanju deleža kritja ALI si spremenil storitev
                                        if (_.get(payload, 'service[0].price')) {
                                            _.set(
                                                newCase,
                                                'totalPrice',
                                                payload.service[0].price * newCase.numOfVisits,
                                            );
                                            if (
                                                _.get(newCase, 'customerPrice') + _.get(newCase, 'coveragePrice') <
                                                newCase.totalPrice
                                            ) {
                                                _.set(newCase, 'coveragePercentage', 100);
                                                _.set(
                                                    newCase,
                                                    'coveragePrice',
                                                    payload.service[0].price * newCase.numOfVisits,
                                                );
                                                _.set(newCase, 'customerPrice', 0);
                                            }
                                        } else {
                                            //nepovezani izvajalci imajo service[0].price -> undefined
                                            _.set(
                                                newCase,
                                                'totalPrice',
                                                (Number(payload.service[0].coverageAmount) +
                                                    Number(payload.service[0].selfCoverageAmount)) *
                                                    newCase.numOfVisits,
                                            );
                                        }
                                    }
                                }
                            }
                            if (payload.selectedSeparatedDate) {
                                newCase.selectedSeparatedDate = payload.selectedSeparatedDate;
                            }
                            if (payload.separatedConcluded) {
                                newCase.separatedConcluded = payload.separatedConcluded;
                            }

                            let selectedContractor = null;
                            if (_.get(state, 'case.services[0].id') === _.get(payload, 'service[0].id')) {
                                selectedContractor = state.selectedContractor;
                            }

                            if (payload.waitingQueue) {
                                newCase.waitingQueue = true;
                            } else {
                                newCase.waitingQueue = false;
                            }
                            if (payload.additionalData) {
                                newCase.additionalData = payload.additionalData;
                            }
                            patchState({
                                authorityCaseStatus: AuthorityCaseStatus.CREATED,
                                slot: null,
                                secondsLeft: null,
                                selectedContractor: selectedContractor,
                                case: newCase,
                            });
                            // debugger;
                            // this.authorizationRest
                            //     .updateCaseCoverage(
                            //         new UpdateCasePricesRequestDTO().deserialize({
                            //             bsId: this.storage.getBusinessSubject().id,
                            //             caseId: state.case.caseId,
                            //             totalPrice: _.get(state.case, 'totalPrice', 0),
                            //             customerPrice: _.get(state.case, 'customerPrice', 0),
                            //             coveragePrice: _.get(state.case, 'coveragePrice', 0),
                            //             coveragePercentage: _.get(state.case, 'coveragePercentage', 0),
                            //             numberOfServices: _.get(state.case, 'numOfVisits'),
                            //         }),
                            //     )
                            //     .subscribe((el) => {});
                        }),
                    );
                }),
            );
    }

    @Action(NewCase)
    create({ getState, patchState, setState }: StateContext<BaseCaseStateModel>, { payload }: NewCase) {
        const state = getState();
        payload.customer = state.customer;
        payload.user = new User().deserialize({
            id: state.authorityCases.appUserId,
            name: state.authorityCases.appUserName,
        });

        // if (state.urlData && state.urlData instanceof UrlDataBS1) {

        // const data = this.storage.getBusinessSubject();
        if (state.urlData) {
            const services: UrlService[] = (<UrlDataBS1>state.urlData).service;
            if (services) {
                payload.placeholder = new Placeholder().deserialize({ services: services });
            }
        }

        return this.authorizationRest
            .handleAuthorizationFromAuthority(
                this.storage.getBusinessSubject().id,
                state.authorityCases.id,
                payload,
                'v2',
            )
            .pipe(
                switchMap((response) => {
                    if (response.caseAuthority.id !== state.authorityCases.id) {
                        return throwError({
                            errorType: 'DUPLICATE',
                            response: response,
                        });
                    }
                    return of(response);
                }),
                tap(() => {
                    const newCase: AuthorityCaseAuthorization = new AuthorityCaseAuthorization().deserialize({
                        caseId: payload.case_id,
                        services: payload.service,
                        coveragePercentage: payload.coveragePercentage,
                        customerPrice: payload.customerPrice,
                        totalPrice: payload.totalPrice,
                        coveragePrice: payload.coveragePrice,
                        customer: state.customer,
                        user: payload.user,
                        numOfVisits: 1,
                        maxServices: 15,
                        bsServiceId: payload.bsServiceId,
                        // maxServices: _.get(payload, 'placeholder.services'),
                    });
                    if (_.has(payload, 'placeholder.services')) {
                        if (payload.placeholder.services.length > 0) {
                            newCase.numOfVisits = newCase.maxServices = payload.placeholder.services.length;
                        }
                    }

                    if (state.triage && state.triage.id) {
                        this.triageRest
                            .concludeTriage(this.storage.getBusinessSubject().id, state.triage.id)
                            .subscribe();
                    }

                    let additionalData = undefined;
                    if (payload.additionalData) {
                        additionalData = payload.additionalData;
                    }

                    if (state.selectedContractor) {
                        patchState({
                            case: newCase,
                            authorityCaseStatus: AuthorityCaseStatus.CONTRACTOR_SELECTED,
                            additionalData: additionalData,
                        });
                    } else {
                        patchState({
                            case: newCase,
                            authorityCaseStatus: AuthorityCaseStatus.CREATED,
                            additionalData: additionalData,
                        });
                    }
                }),
            );
    }

    @Action(EditCase)
    editCase({ patchState }: StateContext<BaseCaseStateModel>, { payload }: EditCase) {
        patchState({
            case: payload,
        });
    }

    @Action(ChangeCoverage)
    changeCoverage({ getState, patchState }: StateContext<BaseCaseStateModel>, { payload }: EditCase) {
        const state = getState();
        const caseData = _.cloneDeep(state.case);
        caseData.customerPrice = _.get(payload, 'customerPrice', 0);
        caseData.coveragePrice = _.get(payload, 'coveragePrice', 0);
        caseData.coveragePercentage = _.get(payload, 'coveragePercentage', 0);
        caseData.customerPercent = _.get(payload, 'customerPercent', 0);
        if (!_.isNil(payload.totalPrice)) {
            caseData.totalPrice = payload.totalPrice;
        }
        patchState({
            case: caseData,
        });
    }

    @Action(SetSelectedOrgUnit)
    setSelectedOrgUnit({ patchState }: StateContext<BaseCaseStateModel>, { payload }: any) {
        patchState({
            selectedOrgUnit: payload,
        });
    }

    @Action(SetNumOfServiceCase)
    setNumberOdServicesCase(
        { getState, patchState }: StateContext<BaseCaseStateModel>,
        { payload }: SetNumOfServiceCase,
    ) {
        const state = getState();
        const caseData = _.cloneDeep(state.case);
        caseData.numOfVisits = payload;
        if (!_.isNil(caseData.services[0].price)) {
            caseData.totalPrice = caseData.services[0].price * caseData.numOfVisits;
        } else {
            caseData.totalPrice = undefined;
        }
        caseData.coveragePrice = caseData.totalPrice;
        caseData.coveragePercentage = 100;
        caseData.customerPrice = 0;
        caseData.customerPercent = 0;
        patchState({
            case: caseData,
        });
    }

    @Action(SetStaus)
    setStatus({ patchState }: StateContext<BaseCaseStateModel>, { payload }: SetStaus) {
        patchState({
            authorityCaseStatus: payload,
        });
    }

    // @Action(SetCaseId)
    // SetCaseId({ patchState }: StateContext<BaseCaseStateModel>, { caseId }: SetCaseId) {
    //     // patchState({
    //     //     authorityCaseStatus: caseId,
    //     // });
    // }

    @Action(SetAuthorityType)
    setAuthorityType({ patchState }: StateContext<BaseCaseStateModel>, { payload }: SetAuthorityType) {
        patchState({
            authorityType: payload,
        });
    }

    @Action(AddComment)
    addComment({ getState, dispatch }: StateContext<BaseCaseStateModel>, { comment }: AddComment) {
        const state = getState();

        let request$ = new Observable();

        if (comment) {
            if (state.case && state.case.caseId) {
                request$ = this.authorizationRest
                    .addCaseComments(
                        this.storage.getBusinessSubject().id,
                        state.case.caseId,
                        new Comment().deserialize({ comment: comment }),
                    )
                    .pipe(
                        tap(() => {
                            dispatch(new GetAuthorizationComments());
                        }),
                    );
            } else if (state.triage.id) {
                request$ = this.triageRest
                    .addTriageComments(
                        this.storage.getBusinessSubject().id,
                        state.triage.id,
                        new Comment().deserialize({ comment: comment }),
                    )
                    .pipe(
                        tap(() => {
                            dispatch(new GetTriageComments());
                        }),
                    );
            }
        }
        return request$;
    }

    @Action(AddDocument)
    addDocument({ getState, dispatch }: StateContext<BaseCaseStateModel>, { file }: AddDocument) {
        const state = getState();

        return this.authorizationRest.uploadFile(file, file.name, this.storage.getBusinessSubject().id).pipe(
            flatMap((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                } else if (event instanceof HttpResponse) {
                    console.log('File is completely loaded!');
                }
                if (event.type === HttpEventType.Response) {
                    let request$ = new Observable();
                    if (file) {
                        if (state.case && state.case.caseId) {
                            request$ = this.authorizationRest
                                .addCaseDocument(
                                    this.storage.getBusinessSubject().id,
                                    state.case.caseId,
                                    _.get(event, 'body'),
                                )
                                .pipe(
                                    tap(() => {
                                        dispatch(new GetAuthorizationDocuments());
                                    }),
                                );
                        } else if (state.triage.id) {
                            request$ = this.triageRest
                                .addTriageDocument(
                                    this.storage.getBusinessSubject().id,
                                    state.triage.id,
                                    _.get(event, 'body'),
                                )
                                .pipe(
                                    tap(() => {
                                        dispatch(new GetTriageDocuments());
                                    }),
                                );
                        } else {
                            throwError('Missing case or triage');
                        }
                    }

                    return request$;
                }
                return of(null);
            }),
        );
    }

    @Action(AddCustomerDocument)
    addCustomerDocument({ getState, dispatch }: StateContext<BaseCaseStateModel>, { file }: AddCustomerDocument) {
        const state = getState();
        return this.authorizationRest.uploadFile(file, file.name, this.storage.getBusinessSubject().id).pipe(
            flatMap((event) => {
                if (event.type === HttpEventType.UploadProgress) {
                } else if (event instanceof HttpResponse) {
                    console.log('File is completely loaded!');
                }
                if (event.type === HttpEventType.Response) {
                    let request$ = new Observable();
                    if (file) {
                        if (state.case && state.case.caseId) {
                            request$ = this.authorizationRest
                                .addCaseCustomerDocument(
                                    this.storage.getBusinessSubject().id,
                                    state.case.caseId,
                                    _.get(event, 'body'),
                                )
                                .pipe(
                                    tap(() => {
                                        dispatch(new GetAuthorizationCustomerDocuments());
                                    }),
                                );
                        } else {
                            throwError('Missing case or triage');
                        }
                    }
                    return request$;
                }
                return of(null);
            }),
        );
    }

    // @Action(AddCustomerDocumentsSharedAndUploaded)
    // addCustomerDocumentsSharedAndUploaded(
    //     { getState, dispatch }: StateContext<BaseCaseStateModel>,
    //     { files, sharedfiles, isSafe }: AddCustomerDocumentsSharedAndUploaded,
    // ) {
    //     const state = getState();
    //     const uploadFilesOb: Observable<any>[] = files.map((file) => {
    //         return this.authorizationRest
    //             .uploadFile(file, file.name, this.storage.getBusinessSubject().id)
    //             .pipe(filter((event) => event.type === HttpEventType.Response));
    //     });
    //     return forkJoin(uploadFilesOb).pipe(
    //         map((res) => res.map((el) => el.body)),
    //         mergeMap((res) => {
    //             res = res.concat(sharedfiles.map((el) => el.documentId));
    //             if (isSafe) {
    //                 const array: Observable<any>[] = res.map((id) => {
    //                     return this.authorizationRest
    //                         .addCaseCustomerDocument(this.storage.getBusinessSubject().id, state.case.caseId, id)
    //                         .pipe(
    //                             tap(() => {
    //                                 dispatch(new GetAuthorizationCustomerDocuments());
    //                             }),
    //                         );
    //                 });
    //                 return concat(...array);
    //             } else {
    //                 const c = res.map((el) => new SharedDocument().deserialize({ documentId: el }));
    //                 debugger;
    //                 return dispatch(
    //                     new AddCustomerSharedDocuments(
    //                         res.map((el) => new SharedDocument().deserialize({ documentId: el })),
    //                     ),
    //                 );
    //             }
    //         }),
    //     );
    // }

    // @Action(AddCustomerDocumentsSharedAndUploaded)
    // addCustomerDocumentsSharedAndUploaded(
    //     { getState, dispatch }: StateContext<BaseCaseStateModel>,
    //     { files, sharedfiles, isSafe }: AddCustomerDocumentsSharedAndUploaded,
    // ) {
    //     const state = getState();
    //     const uploadFilesOb: Observable<any>[] = files.map((file) => {
    //         return this.authorizationRest.uploadFile(file, file.name, this.storage.getBusinessSubject().id).pipe(
    //             filter((event) => event.type === HttpEventType.Response),
    //             tap((res) => {
    //                 debugger;
    //             }),
    //         );
    //     });
    //     return forkJoin(uploadFilesOb).pipe(
    //         map((res) => res.map((el) => el.body)),
    //         mergeMap((res) => {
    //             res = res.concat(sharedfiles.map((el) => el.documentId));

    //             const array: Observable<any>[] = res.map((id) => {
    //                 return this.authorizationRest
    //                     .addCaseCustomerDocument(this.storage.getBusinessSubject().id, state.case.caseId, id)
    //                     .pipe(
    //                         tap(() => {
    //                             dispatch(new GetAuthorizationCustomerDocuments());
    //                         }),
    //                     );
    //             });
    //             if (!isSafe) {
    //                 const c = res.map((el) => new SharedDocument().deserialize({ documentId: el }));
    //                 debugger;
    //                 array.push(
    //                     dispatch(
    //                         new AddCustomerSharedDocuments(
    //                             res.map((el) => new SharedDocument().deserialize({ documentId: el })),
    //                         ),
    //                     ),
    //                 );
    //             }
    //             return concat(...array);
    //         }),
    //     );
    // }

    @Action(AddCustomerDocumentsSharedAndUploaded)
    addCustomerDocumentsSharedAndUploaded(
        { getState, dispatch }: StateContext<BaseCaseStateModel>,
        { files, sharedfiles, isSafe }: AddCustomerDocumentsSharedAndUploaded,
    ) {
        const state = getState();
        const uploadFilesOb: Observable<any>[] = files.map((file) => {
            return this.authorizationRest
                .uploadFile(file, file.name, this.storage.getBusinessSubject().id)
                .pipe(filter((event) => event.type === HttpEventType.Response));
        });
        // ce ni nalozene datoteke iz racunalnika se doda samo dokumente iz seznama
        if (uploadFilesOb.length < 1) {
            const array: Observable<any>[] = sharedfiles.map((id) => {
                return this.authorizationRest
                    .addCaseCustomerDocument(this.storage.getBusinessSubject().id, state.case.caseId, id.documentId)
                    .pipe(
                        tap(() => {
                            dispatch(new GetAuthorizationCustomerDocuments());
                        }),
                    );
            });
            if (!isSafe) {
                array.push(dispatch(new AddCustomerSharedDocuments(sharedfiles)));
            }

            return concat(...array);
        }

        return forkJoin(uploadFilesOb).pipe(
            map((res) => res.map((el) => el.body)),
            mergeMap((res) => {
                res = res.concat(sharedfiles.map((el) => el.documentId));

                const array: Observable<any>[] = res.map((id) => {
                    return this.authorizationRest
                        .addCaseCustomerDocument(this.storage.getBusinessSubject().id, state.case.caseId, id)
                        .pipe(
                            tap(() => {
                                dispatch(new GetAuthorizationCustomerDocuments());
                            }),
                        );
                });
                if (!isSafe) {
                    // const c = res.map((el) => new SharedDocument().deserialize({ documentId: el }));
                    array.push(
                        dispatch(
                            new AddCustomerSharedDocuments(
                                res.map((el) => new SharedDocument().deserialize({ documentId: el })),
                            ),
                        ),
                    );
                }
                return concat(...array);
            }),
        );
    }

    // private test(documents, caseId:string, isSafe:boolean=true):Observable<any>{
    //     // res = res.concat(sharedfiles.map((el) => el.documentId));

    //     const array: Observable<any>[] = documents.map((id) => {
    //         return this.authorizationRest
    //             .addCaseCustomerDocument(this.storage.getBusinessSubject().id, state.case.caseId, id)
    //             .pipe(
    //                 tap(() => {
    //                     dispatch(new GetAuthorizationCustomerDocuments());
    //                 }),
    //             );
    //     });
    //     if (!isSafe) {
    //         const c = res.map((el) => new SharedDocument().deserialize({ documentId: el }));
    //         debugger;
    //         array.push(
    //             dispatch(
    //                 new AddCustomerSharedDocuments(
    //                     res.map((el) => new SharedDocument().deserialize({ documentId: el })),
    //                 ),
    //             ),
    //         );
    //     }
    //     return concat(...array);
    // }

    @Action(AddCustomerSharedDocuments)
    addCustomerSharedDocuments(
        { getState, dispatch }: StateContext<BaseCaseStateModel>,
        { files }: AddCustomerSharedDocuments,
    ) {
        const state = getState();
        if (_.isNil(state.customer.email)) {
            throwError('Stranka nima vnešenega email naslova');
            return;
        }
        const data: SharedDocumentPost = new SharedDocumentPost().deserialize({
            name: `${state.customer.name} ${state.customer.surname}`,
            email: state.customer.email,
            caseid: state.case.caseId,
            documentIds: files.map((el) => el.documentId),
        });
        return this.authorizationRest.addSharedDocumentToCustomer(this.storage.getBusinessSubject().id, data).pipe(
            tap(() => {
                dispatch(new GetAuthorizationCustomerDocuments());
            }),
        );
    }

    @Action(SetInitComment)
    setInitComment({ patchState, getState }: StateContext<BaseCaseStateModel>, { comment }: SetInitComment) {
        const state = getState();
        patchState({
            initAttachments: {
                comment: comment,
                documents: state.initAttachments ? state.initAttachments.documents : [],
            },
        });
    }

    @Action(SetInitDocuments)
    setInitDocuments({ patchState, getState }: StateContext<BaseCaseStateModel>, { files }: SetInitDocuments) {
        const state = getState();
        patchState({
            initAttachments: {
                comment: state.initAttachments ? state.initAttachments.comment : null,
                documents: files,
            },
        });
    }

    @Action(GetTriageComments)
    getTriageComments({ patchState, getState }: StateContext<BaseCaseStateModel>, {}: GetTriageComments) {
        const state = getState();
        if (state.triage && state.triage.id) {
            return this.triageRest.getTriageComments(this.storage.getBusinessSubject().id, state.triage.id).pipe(
                tap((comments) => {
                    const newState = getState();
                    patchState({
                        triage: new AuthorityCaseTriage().deserialize({
                            ...newState.triage,
                            comments: comments,
                        }),
                    });
                }),
            );
        } else {
            return of([]);
        }
    }

    @Action(GetTriageDocuments)
    getTriageDocuments({ patchState, getState }: StateContext<BaseCaseStateModel>, {}: GetTriageDocuments) {
        const state = getState();
        if (state.triage && state.triage.id) {
            return this.triageRest.getTriageDocuments(this.storage.getBusinessSubject().id, state.triage.id).pipe(
                tap((documents) => {
                    const newState = getState();
                    patchState({
                        triage: new AuthorityCaseTriage().deserialize({
                            ...newState.triage,
                            documents: documents,
                        }),
                    });
                }),
            );
        } else {
            return of([]);
        }
    }

    @Action(GetTriageContractorComments)
    getTriageContractorComments(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        {}: GetTriageContractorComments,
    ) {
        const state = getState();
        if (state.triage && state.triage.id) {
            return this.triageRest
                .getTriageContractorComments(this.storage.getBusinessSubject().id, state.triage.id)
                .pipe(
                    tap((comments) => {
                        const newState = getState();
                        patchState({
                            triage: new AuthorityCaseTriage().deserialize({
                                ...newState.triage,
                                contractorComments: comments,
                            }),
                        });
                    }),
                );
        } else {
            return of([]);
        }
    }

    @Action(GetTriageContractorDocuments)
    getTriageContractorDocuments(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        {}: GetTriageContractorDocuments,
    ) {
        const state = getState();
        if (state.triage && state.triage.id) {
            return this.triageRest
                .getTriageContractorDocuments(this.storage.getBusinessSubject().id, state.triage.id)
                .pipe(
                    tap((documents) => {
                        const newState = getState();
                        patchState({
                            triage: new AuthorityCaseTriage().deserialize({
                                ...newState.triage,
                                contractorDocuments: documents,
                            }),
                        });
                    }),
                );
        } else {
            return of([]);
        }
    }

    @Action(GetAuthorizationComments)
    getAuthorizationComments(ctx: StateContext<BaseCaseStateModel>, {}: GetAuthorizationComments) {
        const state = ctx.getState();
        if (state.case && state.case.caseId) {
            return this.authorizationRest.getCaseComments(this.storage.getBusinessSubject().id, state.case.caseId).pipe(
                tap((comments) => {
                    const newState = ctx.getState();

                    // ctx.setState(
                    //     patch<BaseCaseStateModel>({
                    //         case: patch<AuthorityCaseAuthorization>({ comments: comments }),
                    //     }),
                    // );
                    ctx.patchState({
                        case: new AuthorityCaseAuthorization().deserialize({
                            ...newState.case,
                            comments: comments,
                        }),
                    });
                }),
            );
        } else {
            return of([]);
        }
    }

    @Action(GetAuthorizationDocuments)
    getAuthorizationDocuments(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        {}: GetAuthorizationDocuments,
    ) {
        const state = getState();
        if (state.case && state.case.caseId) {
            return this.authorizationRest
                .getCaseDocuments(this.storage.getBusinessSubject().id, state.case.caseId)
                .pipe(
                    tap((documents) => {
                        const newState = getState();
                        patchState({
                            case: new AuthorityCaseAuthorization().deserialize({
                                ...newState.case,
                                documents: documents,
                            }),
                        });
                    }),
                );
        } else {
            return of([]);
        }
    }

    @Action(GetAuthorizationCustomerDocuments)
    getAuthorizationCustomerDocuments(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        {}: GetAuthorizationDocuments,
    ) {
        const state = getState();
        if (state.case && state.case.caseId) {
            return this.authorizationRest
                .getCaseCustomerDocuments(this.storage.getBusinessSubject().id, state.case.caseId)
                .pipe(
                    tap((documents) => {
                        const newState = getState();
                        patchState({
                            case: new AuthorityCaseAuthorization().deserialize({
                                ...newState.case,
                                customerDocuments: documents,
                            }),
                        });
                    }),
                );
        } else {
            return of([]);
        }
    }

    @Action(GetAuthorizationContractorComments)
    getAuthorizationContractorComments(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        {}: GetAuthorizationContractorComments,
    ) {
        const state = getState();
        if (state.case && state.case.caseId) {
            return this.authorizationRest
                .getCaseContractorComments(this.storage.getBusinessSubject().id, state.case.caseId)
                .pipe(
                    tap((comments) => {
                        const newState = getState();
                        patchState({
                            case: new AuthorityCaseAuthorization().deserialize({
                                ...newState.case,
                                contractorComments: comments,
                            }),
                        });
                    }),
                );
        } else {
            return of([]);
        }
    }

    @Action(GetAuthorizationContractorDocuments)
    getAuthorizationContractorDocuments(
        { patchState, getState }: StateContext<BaseCaseStateModel>,
        {}: GetAuthorizationContractorDocuments,
    ) {
        const state = getState();
        if (state.case && state.case.caseId) {
            return this.authorizationRest
                .getCaseContractorDocuments(this.storage.getBusinessSubject().id, state.case.caseId)
                .pipe(
                    tap((documents) => {
                        const newState = getState();
                        patchState({
                            case: new AuthorityCaseAuthorization().deserialize({
                                ...newState.case,
                                contractorDocuments: documents,
                            }),
                        });
                    }),
                );
        } else {
            return of([]);
        }
    }

    @Action(SetAdditionalData)
    SetAdditionalData({ patchState }: StateContext<BaseCaseStateModel>, { payload }: SetAdditionalData) {
        patchState({
            additionalData: payload,
        });
    }
    @Action(SetToken)
    SetToken({ patchState }: StateContext<BaseCaseStateModel>, { token }: SetToken) {
        patchState({
            token: token,
        });
    }

    @Action(NewCaseFromExistingAuthority)
    NewCaseFromExistingAuthority(
        { getState, setState }: StateContext<BaseCaseStateModel>,
        {}: NewCaseFromExistingAuthority,
    ) {
        //save current customer + authorityID
        const state = getState();
        const currentAuthority = { ...state.authorityCases };
        delete currentAuthority.cases;
        //reset the rest
        setState({
            ...baseStateDefaults,
            authorityCases: new AuthorityCases().deserialize(currentAuthority),
            customer: new Customer().deserialize(state.customer),
            authorityCaseStatus: AuthorityCaseStatus.SELECTED,
            authorityType: state.authorityType || AuthorityType.CASE,
        });
    }
}
