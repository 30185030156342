import { Placeholder } from './placeholder.model';
import { AuthorizationOrder } from './authorizationOrder.model';
import { Serializable } from '../deserialize.service';
import { Customer } from '../customer.model';
import { Service } from '../service/service.model';
import { User } from '../user.model';

export class AuthorizationRequest implements Serializable<AuthorizationRequest> {
    case_id?: string;
    user?: User;
    customer?: Customer;
    service?: Service[];
    numOfVisits?: number;
    coveragePercentage?: number;
    selectedSeparatedDate?: Date;
    customerPrice: number;
    totalPrice: number;
    coveragePrice: number;
    waitingQueue: boolean;
    placeholder?: Placeholder;
    separatedConcluded: boolean;
    additionalData: any;
    claimFile: string;
    bsServiceId: string;
    deserialize(input) {
        this.case_id = input.case_id;
        // this.contractorId = input.contractorId;
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        if (input.service) {
            this.service = input.service.map((el) => new Service().deserialize(el));
        }
        this.numOfVisits = input.numOfVisits;
        if (input.user) {
            this.user = new User().deserialize(input.user);
        }
        if (input.waitingQueue) {
            this.waitingQueue = input.waitingQueue;
        }
        this.selectedSeparatedDate = input.selectedSeparatedDate;
        this.selectedSeparatedDate = input.selectedSeparatedDate;
        this.separatedConcluded = input.separatedConcluded;
        this.coveragePercentage = input.coveragePercentage;
        this.customerPrice = input.customerPrice;
        this.totalPrice = input.totalPrice;
        this.coveragePrice = input.coveragePrice;
        this.placeholder = input.placeholder;
        this.claimFile = input.claimFile;
        this.bsServiceId = input.bsServiceId;

        if (input.additionalData) {
            this.additionalData = input.additionalData;
        }

        return this;
    }

    public getOrderObj(): AuthorizationOrder {
        return new AuthorizationOrder().deserialize({
            externalBsUserId: this.user.id,
            caseId: this.case_id,
            customer: this.customer,
            services: this.service,
            coveragePercentage: this.coveragePercentage,
            selectedSeparatedDate: this.selectedSeparatedDate,
        });
    }
}
