import { Customer } from '../customer.model';
import { Contractor } from '../contractor.model';
import { Service } from '../service/service.model';
import { Serializable } from '../deserialize.service';
import * as _ from 'lodash';
// import * as moment from 'moment';

export class AddToWaitingQueueRequest implements Serializable<AddToWaitingQueueRequest> {
    userId: string;
    userName: string;
    contractor: Contractor;
    customer: Customer;
    comment: string;
    isBs: boolean;
    bsid: number;
    caseId: string;
    orderedServices: any[];
    insurancePolicy: string;
    insuranceNumber: string;
    insuranceDiagnose: string;
    insuranceTreatmentReason: string;
    insuranceServiceId: string;
    insuranceServiceName: string;
    insuranceServicePrice: number;
    telemedicine: boolean;

    contractorId: string;
    contractorServiceExternalId: string;
    subcontractorExternalId: string;
    orgUnitExternalId: string;
    deserialize(input: any) {
        this.userId = input.userId;
        this.userName = input.userName;
        this.bsid = input.bsid;
        this.caseId = input.caseId;
        if (input.contractor) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        this.customer = new Customer().deserialize(input.customer);
        this.comment = input.comment;
        if (input.contractor) {
            this.contractor = new Contractor().deserialize(input.contractor);
        }
        if (input.customer) {
            this.customer = new Customer().deserialize(input.customer);
        }
        this.isBs = input.isBs;
        if (input.orderedServices) {
            this.orderedServices = input.orderedServices;
        }
        this.insurancePolicy = input.insurancePolicy;
        this.insuranceNumber = input.insuranceNumber;
        this.insuranceDiagnose = input.insuranceDiagnose;
        this.insuranceTreatmentReason = input.insuranceTreatmentReason;
        this.insuranceServiceId = input.insuranceServiceId;
        this.insuranceServiceName = input.insuranceServiceName;
        this.insuranceServicePrice = input.insuranceServicePrice;
        this.telemedicine = input.telemedicine;

        this.contractorId = input.contractorId;
        this.contractorServiceExternalId = input.contractorServiceExternalId;
        this.subcontractorExternalId = input.subcontractorExternalId;
        this.orgUnitExternalId = input.orgUnitExternalId;

        return this;
    }
}
